<template>
    <div></div>
</template>
<script>
export default {
    name: 'IncomingPage',
    created () {
        this.$router.push( { name: 'ZoomClient', params: { incomingInfo: this.$route.params.incomingInfo } } )
        console.log("----- incomingPage ----------")
        console.log("incomingPage")
        console.log("----------------------------")
    }
}
</script>